:root {
  --lokalac-primary-1: #0c67a0;
  --lokalac-primary-2: #0f7ec5;
  --lokalac-primary-3: #ccddf2;
  --lokalac-primary-4: #89caf4;
  --lokalac-background: #f1f2f6;
  --lokalac-text-light: #fff;
  --lokalac-text-dark: #000;

  --lokalac-gray-1: #e1e1e1;
  --lokalac-gray-2: #9a9a9a;
  --lokalac-white: #fff;
  --lokalac-black: #353935;
  --lokalac-gold: #ffd700;
  --lokalac-red: #ff4495;

  --lokalac-cat-0: #40c4ff; // kultura
  --lokalac-cat-1: #eecc0b; // pravo-i-pravda
  --lokalac-cat-2: #f36c4f; // incident
  --lokalac-cat-3: #141f52; // ekonomija
  --lokalac-cat-4: #6153cc; // edu
  --lokalac-cat-5: #20c997; // oko
  --lokalac-cat-6: #d88dbc; // zdrav
  --lokalac-cat-7: #ff4495; // life
  --lokalac-cat-8: #232d42; // posao
  --lokalac-cat-9: #d30b0d; // politika
  --lokalac-cat-10: #5da366; // relig
  --lokalac-cat-11: #0457ac; // znan
  --lokalac-cat-12: #02a5dc; // vijest
  --lokalac-cat-13: #000080; //sport
  --lokalac-cat-14: #ed5565; // sukob
  --lokalac-cat-15: #75b4e3; // vrijeme
  --lokalac-cat-16: #dbae58; // sve

  --lokalac-cat-17: #093145;
  --lokalac-cat-18: #f36c4f;

  --lokalac-content-max-width: 1920px;
  --lokalac-item-gap: 15px;
  --lokalac-card-width-large: 615px;
  --lokalac-card-width-normal: 300px;
  --lokalac-card-height-normal: 415px;
  --lokalac-card-height-small: 200px;

  --lokalac-xxxl: 36px;
  --lokalac-xxl: 24px;
  --lokalac-xl: 22px;
  --lokalac-l: 18px;
  --lokalac-m: 16px;
  --lokalac-s: 14px;
  --lokalac-xs: 13px;
  --lokalac-xxs: 11px;

  --type-col-kultura: #40c4ff; // kultura
  --type-col-pravo-i-pravda: #eecc0b; // pravo-i-pravda
  --type-col-incidenti: #f36c4f; // incident
  --type-col-ekonomija-i-biznis: #141f52; // ekonomija
  --type-col-edukacija: #6153cc; // edu
  --type-col-okoliš: #20c997; // oko
  --type-col-zdravlje: #d88dbc; // zdrav
  --type-col-lifestyle: #ff4495; // life
  --type-col-posao: #232d42; // posao
  --type-col-politika: #d30b0d; // politika
  --type-col-religija: #5da366; // relig
  --type-col-znanost-i-tehnologija: #0457ac; // znan
  --type-col-vijesti: #02a5dc; // vijest
  --type-col-sport: #000080; //sport
  --type-col-sukobi: #ed5565; // sukob
  --type-col-vrijeme: #75b4e3; // vrijeme
  --type-col-sve: #dbae58; // sve
}

.type-kultura {
  background: var(--lokalac-cat-0);
}
.type-pravo-i-pravda {
  background: var(--lokalac-cat-1);
}
.type-incidenti {
  background: var(--lokalac-cat-2);
}
.type-ekonomija-i-biznis {
  background: var(--lokalac-cat-3);
}
.type-edukacija {
  background: var(--lokalac-cat-4);
}
.type-okoliš {
  background: var(--lokalac-cat-5);
}
.type-zdravlje {
  background: var(--lokalac-cat-6);
}
.type-lifestyle {
  background: var(--lokalac-cat-7);
}
.type-posao {
  background: var(--lokalac-cat-8);
}
.type-politika {
  background: var(--lokalac-cat-9);
}
.type-religija {
  background: var(--lokalac-cat-10);
}
.type-znanost-i-tehnologija {
  background: var(--lokalac-cat-11);
}
.type-vijesti {
  background: var(--lokalac-cat-12);
}
.type-sport {
  background: var(--lokalac-cat-13);
}
.type-sukobi {
  background: var(--lokalac-cat-14);
}
.type-vrijeme {
  background: var(--lokalac-cat-15);
}
.type-sve {
  background: var(--lokalac-cat-16);
}

/* Temporary hardcoded solution */
.border-kultura {
  border-bottom: 3px solid var(--lokalac-cat-0);
}
.border-pravo-i-pravda {
  border: 3px solid var(--lokalac-cat-1);
}
.border-incidenti {
  border: 3px solid var(--lokalac-cat-2);
}
.border-ekonomija-i-biznis {
  border: 3px solid var(--lokalac-cat-3);
}
.border-edukacija {
  border: 3px solid var(--lokalac-cat-4);
}
.border-okoliš {
  border: 3px solid var(--lokalac-cat-5);
}
.border-zdravlje {
  border: 3px solid var(--lokalac-cat-6);
}
.border-lifestyle {
  border: 3px solid var(--lokalac-cat-7);
}
.border-posao {
  border: 3px solid var(--lokalac-cat-8);
}
.border-politika {
  border: 3px solid var(--lokalac-cat-9);
}
.border-religija {
  border: 3px solid var(--lokalac-cat-10);
}
.border-znanost-i-tehnologija {
  border: 3px solid var(--lokalac-cat-11);
}
.border-vijesti {
  border: 3px solid var(--lokalac-cat-12);
}
.border-sport {
  border: 3px solid var(--lokalac-cat-13);
}
.border-sukobi {
  border: 3px solid var(--lokalac-cat-14);
}
.border-vrijeme {
  border: 3px solid var(--lokalac-cat-15);
}
.border-sve {
  border: 3px solid var(--lokalac-cat-16);
}
.border-transparent {
  border: 3px solid transparent;
}
